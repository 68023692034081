/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Error as ErrorPage, Layout } from "../components"
import Chart from "../components/Chart"
import InfoDialog from "../components/Dialog/Info"
import { LicenseSelector } from "../components/LicenseSelector"
import TooltipComponent from "../components/TooltipComponent"
import {
  getBusinessesWithApplicationSubmissionsWithAttribute,
  getApplicationSubmissionsForPartner,
  getPoliciesForPartner,
  getTotalPartnerRevShare,
  getTotalPremiumForDates,
} from "../components/dashboard/dashboard.service"
import { useAuth } from "../context/authContext"
import { useLicenseContext } from "../context/licenseContext"
import { IApplicationSubmission, IPolicy } from "../db/models"
import {
  allPolicyCodes,
  dev_log,
  otherPolicies,
  policyIsMgl,
} from "../utilities"
import { MONTHS, QUARTERS } from "../utils/constants"
import { getMonthDates } from "../utils/getMonthDates"
import { StartEndDates, getQuarterDates } from "../utils/getQuarterDates"
import { getRoundedIntegerValue } from "../utils/getRoundedIntegerValue"
import * as Sentry from "@sentry/nextjs"
import { DonutChart, Legend, Select, SelectItem } from "@tremor/react"
import { Card, Metric, Text, Flex, Title, BarList, Grid } from "@tremor/react"
import dayjs from "dayjs"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import numeral from "numeral"
// import { useRouter } from "next/router"
import { useEffect, useMemo, useRef, useState } from "react"

dayjs.extend(quarterOfYear)

const dataFormatter = (number: number) =>
  Intl.NumberFormat("us").format(number).toString()

const getGreeting = () => {
  const date = new Date()
  const currentTime = date.getHours()

  if (currentTime >= 0 && currentTime <= 12) {
    return "Good Morning"
  } else if (currentTime > 12 && currentTime <= 18) {
    return "Good Afternoon"
  } else {
    return "Good Evening"
  }
}

interface StatCategory {
  title: string
  metric: string | null
  metricPrev?: string
  tooltip?: string
}

function getYearsFrom2022(): string[] {
  const currentYear = new Date().getFullYear()
  return Array.from({ length: currentYear - 2021 }, (_, i) => String(2022 + i))
}

export default function HomePage() {
  const greetingText = getGreeting()
  // const router = useRouter()
  const [hasError, setHasError] = useState(false)
  const { user } = useAuth()
  const { selectedLicense, getSelectedLicense } = useLicenseContext()

  const [loading, setLoading] = useState(true)

  const [selectedQuarterOrMonth, setSelectedQuarterOrMonth] =
    useState<string>("Q1")
  const [selectedYear, setSelectedYear] = useState<string>(
    dayjs().year().toString()
  )
  const currentDateRange = useMemo(() => {
    let dates: StartEndDates = {
      startDate: "",
      endDate: "",
    }
    if (QUARTERS.includes(selectedQuarterOrMonth)) {
      dates = getQuarterDates(selectedQuarterOrMonth, selectedYear)
    } else {
      dates = getMonthDates(selectedQuarterOrMonth, selectedYear)
    }
    return [dates.startDate, dates.endDate]
  }, [selectedQuarterOrMonth, selectedYear, getQuarterDates, getMonthDates])

  const [years, setYears] = useState<string[]>([
    new Date().getFullYear().toString(),
  ])

  // useEffect(() => {
  //   console.table({
  //     year: selectedYear,
  //     quarter: selectedQuarterOrMonth,
  //   })
  // }, [selectedQuarterOrMonth, selectedYear])

  useEffect(() => {
    // Default quarter to current quarter
    setSelectedQuarterOrMonth(`Q${dayjs(new Date()).quarter()}`)

    // Default year to current year
    setSelectedYear(new Date().getFullYear().toString())

    // // Set years to all years from 2022 until today
    setYears(getYearsFrom2022())
  }, [])

  const [categories, setCategories] = useState<StatCategory[]>([
    // {
    //   title: "Total Applications Created",
    //   metric: null,
    //   // metricPrev: "$ 9,456",
    //   tooltip:
    //     "The total number of applications started in the selected quarter.",
    // },
    // {
    //   title: "Applications Completed",
    //   metric: null,
    //   // metricPrev: "$ 45,564",
    // },
    // {
    //   title: "Total Premium",
    //   metric: null,
    //   tooltip: "The total premium sold in the selected quarter.",
    // },
    {
      title: "Total Partner Revenue Share",
      metric: null,
      tooltip: "The total amount of commission earned in the selected quarter.",
    },
  ])
  const [chartData, setChartData] = useState([])
  const [totalCommissionEarned, setTotalCommissionEarned] = useState(0)

  const [applications, setApplications] = useState<IApplicationSubmission[]>([])
  const [applicationsFiltered, setApplicationsFiltered] = useState<
    IApplicationSubmission[]
  >([])
  const [businessesWithAppsInProgress, setBusinessesWithAppsInProgress] =
    useState<number>(0)
  const [
    businessesWithAppsSubmittedForQuotes,
    setBusinessesWithAppsSubmittedForQuotes,
  ] = useState<number>(0)
  // const [applicationsNoPolicySelected, setApplicationsNoPolicySelected] =
  //   useState<Application[]>([])
  // const [applicationsNoQuotesReturned, setApplicationsNoQuotesReturned] =
  //   useState<Application[]>([])
  const [policies, setPolicies] = useState<IPolicy[]>([])
  const [policiesFiltered, setPoliciesFiltered] = useState<IPolicy[]>([])
  // const [totalAmountOwedFromEndorsements, setTotalAmountOwedFromEndorsements] =
  //   useState(0)

  function displayPolicyType(policyType: string) {
    return (
      (!selectedLicense.policyType && policyType !== "NOT_SELECTED") ||
      selectedLicense.policyType.includes(policyType) ||
      otherPolicies.includes(policyType) ||
      policyIsMgl(policyType)
    )
  }

  const fetchData = async (
    startDate = dayjs().startOf("year").toDate(),
    endDate = new Date()
  ) => {
    try {
      setLoading(true)
      const _selectedLicense = selectedLicense || (await getSelectedLicense())
      dev_log("_selectedLicense:", _selectedLicense)
      // dev_log(licenses)

      if (_selectedLicense && user?.id) {
        const _businessesWithAppsInProgress =
          await getBusinessesWithApplicationSubmissionsWithAttribute(
            _selectedLicense.id,
            `${dayjs(startDate).format("YYYY-MM-DD")}T00:00:00`,
            `${dayjs(endDate).format("YYYY-MM-DD")}T23:59:59`,
            "NOT_YET_COMPLETED"
          )
        setBusinessesWithAppsInProgress(_businessesWithAppsInProgress)
        const _businessesWithAppsSubmittedForQuotes =
          await getBusinessesWithApplicationSubmissionsWithAttribute(
            _selectedLicense.id,
            `${dayjs(startDate).format("YYYY-MM-DD")}T00:00:00`,
            `${dayjs(endDate).format("YYYY-MM-DD")}T23:59:59`,
            "SENT_TO_QUOTING_ENGINE"
          )
        setBusinessesWithAppsSubmittedForQuotes(
          _businessesWithAppsSubmittedForQuotes
        )
        const _applicationsForPartner =
          await getApplicationSubmissionsForPartner(
            _selectedLicense.id,
            `${dayjs(startDate).format("YYYY-MM-DD")}T00:00:00`,
            `${dayjs(endDate).format("YYYY-MM-DD")}T23:59:59`
          )

        const _applications = [
          ...(_applicationsForPartner["NOT_YET_COMPLETED"] || []),
          ...(_applicationsForPartner["SENT_TO_QUOTING_ENGINE"] || []),
          ...(_applicationsForPartner["COMPLETED"] || []),
          ...(_applicationsForPartner["REACHED_OUT_TO_LEAD"] || []),
          ...(_applicationsForPartner["DISREGARD"] || []),
        ]

        const _applicationsFiltered = _applications.filter(
          (a: IApplicationSubmission) =>
            dayjs(a.createdAt).isAfter(currentDateRange[0]) &&
            dayjs(a.createdAt).isBefore(
              dayjs(currentDateRange[1]).add(1, "day")
            ) &&
            a.license.id === _selectedLicense.id
        )
        setApplications(_applications)
        setApplicationsFiltered(_applicationsFiltered)
        dev_log("_applicationsFiltered", _applicationsFiltered)

        const policiesRes = await getPoliciesForPartner(
          `${dayjs(startDate).format("YYYY-MM-DD")}T00:00:00`,
          `${dayjs(endDate).format("YYYY-MM-DD")}T23:59:59`
        )
        const _policies = policiesRes?.policies || []

        const _policiesFiltered = _policies.filter(
          (p: IPolicy) =>
            dayjs(p.createdAt).isAfter(currentDateRange[0]) &&
            dayjs(p.createdAt).isBefore(
              dayjs(currentDateRange[1]).add(1, "day")
            ) &&
            p.license.id === _selectedLicense.id
        )
        setPolicies(_policies)
        setPoliciesFiltered(_policiesFiltered)

        // setTotalAmountOwedFromEndorsements(
        //   policiesRes?.endorsements[selectedLicense?.id] || 0
        // )
      }
    } catch (error) {
      setHasError(true)
      console.error(error)
      Sentry.captureException(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    dev_log("~~ Fetch Data ~~")
    fetchData(new Date(currentDateRange[0]), new Date(currentDateRange[1]))
    // else fetchData()
  }, [
    user,
    selectedLicense,
    currentDateRange,
    selectedQuarterOrMonth,
    selectedYear,
  ])

  const [openApplicationsBarData, setOpenApplicationsBarData] = useState([])
  const [submittedApplicationsBarData, setSubmittedApplicationsBarData] =
    useState([])
  const [policiesBoundBarData, setPoliciesBoundBarData] = useState([])
  const [policiesByIndustryBarData, setPoliciesByIndustryBarData] = useState([])
  function filterData() {
    // TODO: Remove filtering dates client side
    const _applicationsFiltered = applications.filter(
      (a: IApplicationSubmission) =>
        dayjs(a.createdAt).isAfter(currentDateRange[0]) &&
        dayjs(a.createdAt).isBefore(dayjs(currentDateRange[1]).add(1, "day")) &&
        a.license.id === selectedLicense?.id &&
        displayPolicyType(a.policyType)
    )
    setApplicationsFiltered(_applicationsFiltered)

    // const _applicationsNoPolicySelected = applications.filter(
    //   (a: Application) =>
    //     dayjs(a.createdAt).isAfter(currentDateRange[0]) &&
    //     dayjs(a.createdAt).isBefore(dayjs(currentDateRange[1]).add(1, "day")) &&
    //     a.license.id === selectedLicense?.id &&
    //     a.policyType === "NOT_SELECTED"
    // )
    // setApplicationsNoPolicySelected(_applicationsNoPolicySelected)

    const _policiesFiltered = policies.filter(
      (p: IPolicy) =>
        dayjs(p.createdAt).isAfter(currentDateRange[0]) &&
        dayjs(p.createdAt).isBefore(dayjs(currentDateRange[1]).add(1, "day")) &&
        p.license.id === selectedLicense?.id &&
        displayPolicyType(p.policyType)
    )

    setPoliciesFiltered(_policiesFiltered)

    dev_log(
      "Businesses with incomplete applications",
      _applicationsFiltered.filter((a) => a.status === "NOT_YET_COMPLETED")
    )
    dev_log(
      "Submitted Applications",
      _applicationsFiltered.filter((a) => a.status === "SENT_TO_QUOTING_ENGINE")
    )
    dev_log(
      "Policies Bound",
      _policiesFiltered.filter((a) => a.status === "BOUND")
    )

    const _openApplicationsBarData = []
    const _submittedApplicationsBarData = []
    const _policiesBoundBarData = []
    const _policiesByIndustryBarData = []
    if (selectedLicense.policyType) {
      const enabledPolicies = selectedLicense.policyType
      // .replace(
      //   "MANAGEMENT",
      //   "DIRECTORS_AND_OFFICERS,EMPLOYMENT_PRACTICES_LIABILITY,DIRECTORS_AND_OFFICERS_EMPLOYMENT_PRACTICES_LIABILITY"
      // )
      const enabledPoliciesList = [
        ...otherPolicies,
        ...enabledPolicies.split(","),
      ]

      // For each enabled policyType, tally up the metrics
      for (const policyType of enabledPoliciesList) {
        const openApplications = _applicationsFiltered.filter(
          (a) =>
            a.status === "NOT_YET_COMPLETED" &&
            (a.policyType === policyType ||
              (policyIsMgl(a.policyType) && policyType === "MANAGEMENT"))
        )

        const totalAppsNotYetCompletedForPolicyTypeCount =
          openApplications.length
        const totalAppsNotYetCompletedCount = _applicationsFiltered.filter(
          (a) => a.status === "NOT_YET_COMPLETED"
        ).length
        const percentageOfAppsNotYetCompletedForPolicyType =
          getRoundedIntegerValue(
            (totalAppsNotYetCompletedForPolicyTypeCount /
              totalAppsNotYetCompletedCount) *
              100
          )

        if (policyType === "MANAGEMENT") {
          _openApplicationsBarData.push({
            name: "Management Liability",
            value: percentageOfAppsNotYetCompletedForPolicyType,
          })
        }
        //
        else if (
          !otherPolicies.includes(policyType) ||
          (otherPolicies.includes(policyType) && openApplications.length > 0)
        ) {
          _openApplicationsBarData.push({
            name: allPolicyCodes[policyType]?.title,
            value: percentageOfAppsNotYetCompletedForPolicyType,
          })
        }

        const submittedApplications = _applicationsFiltered.filter(
          (a) =>
            a.status === "SENT_TO_QUOTING_ENGINE" &&
            (a.policyType === policyType ||
              (policyIsMgl(a.policyType) && policyType === "MANAGEMENT"))
        )
        const totalAppsSubmittedForPolicyTypeCount =
          submittedApplications.length
        const totalAppsSubmittedCount = _applicationsFiltered.filter(
          (a) => a.status === "SENT_TO_QUOTING_ENGINE"
        ).length
        const percentageOfAppsSubmittedForPolicyType = getRoundedIntegerValue(
          (totalAppsSubmittedForPolicyTypeCount / totalAppsSubmittedCount) * 100
        )

        if (policyType === "MANAGEMENT") {
          _submittedApplicationsBarData.push({
            name: "Management Liability",
            value: percentageOfAppsSubmittedForPolicyType,
          })
        }
        //
        else if (
          !otherPolicies.includes(policyType) ||
          (otherPolicies.includes(policyType) &&
            submittedApplications.length > 0)
        ) {
          _submittedApplicationsBarData.push({
            name: allPolicyCodes[policyType]?.title,
            value: percentageOfAppsSubmittedForPolicyType,
          })
        }

        const policiesBound = _policiesFiltered.filter(
          (p) =>
            p.status === "BOUND" &&
            (p.policyType === policyType ||
              (policyIsMgl(p.policyType) && policyType === "MANAGEMENT"))
        )

        if (policyType === "MANAGEMENT") {
          _policiesBoundBarData.push({
            name: "Management Liability",
            value: policiesBound.length,
          })
        }
        //
        else if (
          !otherPolicies.includes(policyType) ||
          (otherPolicies.includes(policyType) && policiesBound.length > 0)
        ) {
          _policiesBoundBarData.push({
            name: allPolicyCodes[policyType]?.title,
            value: policiesBound.length,
          })
        }
      }
    }
    //
    else {
      for (const policy of Object.values(allPolicyCodes)) {
        const pol = policy
        const openApplications = _applicationsFiltered.filter(
          (a) =>
            a.status === "NOT_YET_COMPLETED" &&
            allPolicyCodes[a.policyType]?.title === pol.title
        )

        const totalAppsNotYetCompletedForPolicyTypeCount =
          openApplications.length
        const totalAppsNotYetCompletedCount = _applicationsFiltered.filter(
          (a) => a.status === "NOT_YET_COMPLETED"
        ).length
        const percentageOfAppsNotYetCompletedForPolicyType =
          getRoundedIntegerValue(
            (totalAppsNotYetCompletedForPolicyTypeCount /
              totalAppsNotYetCompletedCount) *
              100
          )

        if (!_openApplicationsBarData.find((item) => item.name === pol.title)) {
          if (
            !otherPolicies.includes(pol.code) ||
            (otherPolicies.includes(pol.code) && openApplications.length > 0)
          ) {
            _openApplicationsBarData.push({
              name: pol.title,
              value: percentageOfAppsNotYetCompletedForPolicyType,
            })
          }
        }

        const submittedApplications = _applicationsFiltered.filter(
          (a) =>
            a.status === "SENT_TO_QUOTING_ENGINE" &&
            allPolicyCodes[a.policyType]?.title === pol.title
        )
        const totalAppsSubmittedForPolicyTypeCount =
          submittedApplications.length
        const totalAppsSubmittedCount = _applicationsFiltered.filter(
          (a) => a.status === "SENT_TO_QUOTING_ENGINE"
        ).length
        const percentageOfAppsSubmittedForPolicyType = getRoundedIntegerValue(
          (totalAppsSubmittedForPolicyTypeCount / totalAppsSubmittedCount) * 100
        )
        if (
          !_submittedApplicationsBarData.find((item) => item.name === pol.title)
        ) {
          if (
            !otherPolicies.includes(pol.code) ||
            (otherPolicies.includes(pol.code) &&
              submittedApplications.length > 0)
          ) {
            _submittedApplicationsBarData.push({
              name: pol.title,
              value: isNaN(percentageOfAppsSubmittedForPolicyType)
                ? 0
                : percentageOfAppsSubmittedForPolicyType,
            })
          }
        }

        const policiesBound = _policiesFiltered.filter(
          (p) =>
            p.status === "BOUND" &&
            allPolicyCodes[p.policyType]?.title === pol.title
        )
        if (!_policiesBoundBarData.find((item) => item.name === pol.title)) {
          if (
            !otherPolicies.includes(pol.code) ||
            (otherPolicies.includes(pol.code) && policiesBound.length > 0)
          ) {
            _policiesBoundBarData.push({
              name: pol.title,
              value: policiesBound.length,
            })
          }
        }
      }
    }

    // dev_log("_openApplicationsBarData", _openApplicationsBarData)
    // dev_log("_submittedApplicationsBarData", _submittedApplicationsBarData)
    // dev_log("_policiesBoundBarData", _policiesBoundBarData)
    setOpenApplicationsBarData(_openApplicationsBarData)
    setSubmittedApplicationsBarData(_submittedApplicationsBarData)
    setPoliciesBoundBarData(_policiesBoundBarData)

    // Get policies by industry type
    const policiesByIndustry: { [industry: string]: number } = {}
    _policiesFiltered
      .filter((p) => p.status === "BOUND")
      .forEach((p) => {
        if (!policiesByIndustry[p.businessClassificationDisplay]) {
          policiesByIndustry[p.businessClassificationDisplay] = 1
        }
        //
        else {
          policiesByIndustry[p.businessClassificationDisplay] =
            policiesByIndustry[p.businessClassificationDisplay] + 1
        }
      })

    dev_log("policiesByIndustry", policiesByIndustry)

    Object.entries(policiesByIndustry).forEach(([industry, count]) => {
      _policiesByIndustryBarData.push({
        name: industry,
        value: count,
      })
    })
    _policiesByIndustryBarData.sort((a, b) => b.value - a.value)
    setPoliciesByIndustryBarData(_policiesByIndustryBarData)
  }

  useEffect(() => {
    dev_log("~~ Filter Data ~~")
    if (selectedLicense) {
      filterData()
    }
  }, [applications, policies])

  async function refreshStats() {
    if (selectedLicense) {
      dev_log("~~ Refresh Stats ~~")
      const partnerRevShareRes = await getTotalPartnerRevShare(
        selectedLicense.id,
        currentDateRange[0],
        currentDateRange[1]
      )

      const adjustedPartnerRevShareData = JSON.parse(
        JSON.stringify(partnerRevShareRes)
      ) as { [date: string]: number }
      delete adjustedPartnerRevShareData.totalPartnerRevShare
      dev_log("adjustedPartnerRevShareData", adjustedPartnerRevShareData)
      const sortedRevenueData = Object.entries(adjustedPartnerRevShareData)
        .map(([date, revenue]) => ({
          date,
          "Partner Revenue Share": revenue,
        }))
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

      dev_log("sortedRevenueData", sortedRevenueData)
      setChartData(sortedRevenueData)

      setTotalCommissionEarned(partnerRevShareRes.totalPartnerRevShare)
      // const _totalCommissionEarned = policyStats.reduce((acc, curr) => {
      //   return acc + curr["Partner Revenue Share"]
      // }, 0)
      // setTotalCommissionEarned(_totalCommissionEarned)
      // dev_log("applicationsFiltered", applicationsFiltered)
      // dev_log("applicationsNoPolicySelected", applicationsNoPolicySelected)

      if (selectedLicense?.name === "Bluevine") {
        const totalPremium = await getTotalPremiumForDates(
          currentDateRange[0],
          currentDateRange[1],
          selectedLicense.id
        )
        setCategories([
          // {
          //   title: "Total Applications Created",
          //   metric: dataFormatter(
          //     applicationsFiltered.length +
          //     applicationsNoPolicySelected.length +
          //     applicationsNoQuotesReturned.length
          //   ),
          //   // metricPrev: "$ 9,456",
          //   tooltip:
          //     "The total number of applications created in the selected quarter.",
          // },
          // {
          //   title: "Applications Completed",
          //   metric: dataFormatter(
          //     applicationsFiltered.filter((a) => a.status === "COMPLETED").length
          //   ),
          // },
          {
            title: "Total Premium",
            metric: numeral(totalPremium).format("$0,0.00"),
            tooltip: "The total premium sold in the selected quarter.",
          },
          {
            title: "Total Partner Revenue Share",
            metric: numeral(partnerRevShareRes.totalPartnerRevShare).format(
              "$0,0.00"
            ),
            // numeral(
            //   _totalCommissionEarned + totalAmountOwedFromEndorsements
            // ).format("$0,0.00"),
            // metricPrev: "$ 9,456",
            tooltip: "The total commission earned in the selected quarter.",
          },
        ])
      }
      //
      else {
        setCategories([
          // {
          //   title: "Total Applications Created",
          //   metric: dataFormatter(
          //     applicationsFiltered.length +
          //     applicationsNoPolicySelected.length +
          //     applicationsNoQuotesReturned.length
          //   ),
          //   // metricPrev: "$ 9,456",
          //   tooltip:
          //     "The total number of applications created in the selected quarter.",
          // },
          // {
          //   title: "Applications Completed",
          //   metric: dataFormatter(
          //     applicationsFiltered.filter((a) => a.status === "COMPLETED").length
          //   ),
          // },
          {
            title: "Total Partner Revenue Share",
            metric: numeral(partnerRevShareRes.totalPartnerRevShare).format(
              "$0,0.00"
            ),
            // numeral(
            //   _totalCommissionEarned + totalAmountOwedFromEndorsements
            // ).format("$0,0.00"),
            // metricPrev: "$ 9,456",
            tooltip: "The total commission earned in the selected quarter.",
          },
        ])
      }
    }
  }

  function useDeepCompareEffect(callback, dependencies) {
    const currentDependenciesRef = useRef()

    if (
      JSON.stringify(currentDependenciesRef.current) !==
      JSON.stringify(dependencies)
    ) {
      currentDependenciesRef.current = dependencies
    }

    useEffect(callback, [currentDependenciesRef.current])
  }

  useDeepCompareEffect(() => {
    refreshStats()
  }, [applicationsFiltered, policiesFiltered])

  const [mobileTooltipOpen, setMobileTooltipOpen] = useState(false)
  const [mobileTooltipTitle, setMobileTooltipTitle] = useState("")
  const [mobileTooltipBody, setMobileTooltipBody] = useState("")

  if (!user?.id) {
    return (
      <Layout>
        <></>
      </Layout>
    )
  }
  return (
    <Layout>
      {hasError ? (
        <div className="m-8">
          <ErrorPage />
        </div>
      ) : (
        <div className="pb-8">
          <div className="bg-white shadow">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="py-6 flex flex-col space-y-3 lg:space-y-0 lg:flex-row items-center justify-between">
                <div className="flex-1 min-w-0">
                  <div className="flex items-center">
                    <div>
                      <div className="flex items-center">
                        <h1 className="ml-3 text-2xl font-extrabold leading-7 text-alt sm:leading-9 sm:truncate">
                          {user?.firstName ? (
                            <>
                              <span className="text-primary">
                                {greetingText}
                              </span>
                              , {user.firstName}
                            </>
                          ) : (
                            <span>Welcome Back</span>
                          )}
                        </h1>
                      </div>
                      <div className="mt-3 ml-2 flex">
                        <LicenseSelector />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="grid max-w-2xl grid-cols-2 gap-x-6 gap-y-8 sm:grid-cols-12 md:col-span-2">
                    {/* QUARTER/MONTH */}
                    <div className="sm:col-span-6">
                      <label className="text-sm text-gray-500">
                        Quarter/Month
                      </label>
                      <div className="mt-2">
                        <Selector
                          options={[...QUARTERS, ...MONTHS]}
                          selected={selectedQuarterOrMonth}
                          handleChange={setSelectedQuarterOrMonth}
                        />
                      </div>
                    </div>
                    {/* YEAR */}
                    <div className="sm:col-span-6">
                      <label className="text-sm text-gray-500">Year</label>
                      <div className="mt-2">
                        <Selector
                          options={years}
                          selected={selectedYear}
                          handleChange={setSelectedYear}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <InfoDialog
            open={mobileTooltipOpen}
            setOpen={setMobileTooltipOpen}
            title={mobileTooltipTitle}
            body={mobileTooltipBody}
          />
          <div className="mt-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div>
                <Grid className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-6">
                  {categories.map((item) => (
                    <Card key={item.title} className="ring-0 bg-white">
                      <Flex className="items-start">
                        <Text className="text-lg">{item.title}</Text>
                        <span className="ml-auto inline-flex">
                          <TooltipComponent
                            tooltipText={item.tooltip}
                            tooltipTitle={item.title}
                            setMobileTooltipOpen={setMobileTooltipOpen}
                            setMobileTooltipTitle={setMobileTooltipTitle}
                            setMobileTooltipBody={setMobileTooltipBody}
                          />
                        </span>
                      </Flex>
                      <Flex className="justify-start items-baseline space-x-3 truncate">
                        {!loading && item.metric ? (
                          <p className="text-gray-700 font-semibold text-2xl">
                            {item.metric}
                          </p>
                        ) : (
                          <div className="mt-4 animate-pulse flex space-x-4">
                            <div className="rounded-md bg-gray-300 h-4 w-16"></div>
                          </div>
                        )}
                        {/* <Text className="truncate">from {item.metricPrev}</Text> */}
                      </Flex>
                    </Card>
                  ))}
                </Grid>
                <Grid
                  numItemsSm={2}
                  numItemsLg={2}
                  className="gap-x-6 gap-y-6 mt-8"
                >
                  <Card className="ring-0 bg-white">
                    <Flex className="items-start">
                      <Title className="pb-7">
                        Businesses with incomplete applications
                      </Title>
                      <span className="ml-auto inline-flex">
                        <TooltipComponent
                          tooltipText={
                            "The total number of businesses that have created an application in the selected quarter, that is still in progress, and has not yet submitted a quote."
                          }
                          tooltipTitle={
                            "Businesses with incomplete applications"
                          }
                          setMobileTooltipOpen={setMobileTooltipOpen}
                          setMobileTooltipTitle={setMobileTooltipTitle}
                          setMobileTooltipBody={setMobileTooltipBody}
                        />
                      </span>
                    </Flex>
                    <Flex
                      justifyContent="start"
                      alignItems="baseline"
                      className="space-x-2"
                    >
                      {loading ? (
                        <div className="mt-4 animate-pulse flex space-x-4">
                          <div className="rounded-md bg-gray-300 h-4 w-16"></div>
                        </div>
                      ) : (
                        <Metric>{businessesWithAppsInProgress}</Metric>
                      )}

                      {/* <Text>In progress</Text> */}
                    </Flex>
                    {/* <Flex className="my-6">
                      <Text>Policy Type</Text>
                      <Text className="text-right">%</Text>
                    </Flex> */}
                    {loading ? (
                      <div className="mt-4 animate-pulse">
                        <div className="rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                      </div>
                    ) : businessesWithAppsInProgress > 0 ? (
                      <>
                        <DonutChart
                          showAnimation
                          variant="pie"
                          data={openApplicationsBarData}
                          valueFormatter={(value) => `${value}%`}
                          className="mt-2"
                        />
                        <br />
                        <Legend
                          categories={openApplicationsBarData.map(
                            (item) => item.name
                          )}
                          className="max-w-xs"
                        />
                      </>
                    ) : null}
                  </Card>
                  <Card className="ring-0 bg-white">
                    <Flex className="items-start">
                      <Title className="pb-7">
                        Businesses with applications submitted for quotes
                      </Title>
                      <span className="ml-auto inline-flex">
                        <TooltipComponent
                          tooltipText={
                            "The total number of businesses that have created an application in the selected quarter, that is filled out completely and that has been submitted for quotes."
                          }
                          tooltipTitle={
                            "Businesses with applications submitted for quotes"
                          }
                          setMobileTooltipOpen={setMobileTooltipOpen}
                          setMobileTooltipTitle={setMobileTooltipTitle}
                          setMobileTooltipBody={setMobileTooltipBody}
                        />
                      </span>
                    </Flex>
                    <Flex
                      justifyContent="start"
                      alignItems="baseline"
                      className="space-x-2"
                    >
                      {loading ? (
                        <div className="mt-4 animate-pulse flex space-x-4">
                          <div className="rounded-md bg-gray-300 h-4 w-16"></div>
                        </div>
                      ) : (
                        <Metric>{businessesWithAppsSubmittedForQuotes}</Metric>
                      )}
                      {/* <Text>Pending policy purchase</Text> */}
                    </Flex>
                    {/* <Flex className="mt-6">
                      <Text>Policy Type</Text>
                      <Text className="text-right">#</Text>
                    </Flex> */}
                    {loading ? (
                      <div className="mt-4 animate-pulse">
                        <div className="rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                      </div>
                    ) : businessesWithAppsSubmittedForQuotes > 0 ? (
                      <>
                        <DonutChart
                          showAnimation
                          variant="pie"
                          data={submittedApplicationsBarData}
                          valueFormatter={(value) => `${value}%`}
                          className="mt-2"
                        />
                        <br />
                        <Legend
                          categories={submittedApplicationsBarData.map(
                            (item) => item.name
                          )}
                          className="max-w-xs"
                        />
                      </>
                    ) : null}
                  </Card>
                  <Card className="ring-0 bg-white">
                    <Flex className="items-start">
                      <Title className="pb-7">Policies Bound</Title>
                      <span className="ml-auto inline-flex">
                        <TooltipComponent
                          tooltipText={
                            "The total number of applications, created in the selected quarter, that are completed and that have resulted in a bound policy."
                          }
                          tooltipTitle={"Policies Bound"}
                          setMobileTooltipOpen={setMobileTooltipOpen}
                          setMobileTooltipTitle={setMobileTooltipTitle}
                          setMobileTooltipBody={setMobileTooltipBody}
                        />
                      </span>
                    </Flex>
                    <Flex
                      justifyContent="start"
                      alignItems="baseline"
                      className="space-x-2"
                    >
                      {loading ? (
                        <div className="mt-4 animate-pulse flex space-x-4">
                          <div className="rounded-md bg-gray-300 h-4 w-16"></div>
                        </div>
                      ) : (
                        <Metric>
                          {policiesBoundBarData.reduce(
                            (acc, curr) => acc + curr.value,
                            0
                          )}
                        </Metric>
                      )}
                      <Text>
                        {policiesBoundBarData.reduce(
                          (acc, curr) => acc + curr.value,
                          0
                        ) > 1
                          ? "Policies"
                          : "Policy"}{" "}
                        bound
                      </Text>
                    </Flex>
                    <Flex className="mt-6">
                      <Text>Policy Type</Text>
                      <Text className="text-right">#</Text>
                    </Flex>
                    {loading ? (
                      <div className="mt-4 animate-pulse">
                        <div className="rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                      </div>
                    ) : (
                      <BarList
                        data={policiesBoundBarData}
                        valueFormatter={dataFormatter}
                        className="mt-2"
                      />
                    )}
                  </Card>
                  {policiesByIndustryBarData.reduce(
                    (acc, curr) => acc + curr.value,
                    0
                  ) > 0 && (
                    <Card className="ring-0 bg-white">
                      <Flex className="items-start">
                        <Title className="pb-7">Policies by Industry</Title>
                        <span className="ml-auto inline-flex">
                          <TooltipComponent
                            tooltipText={
                              "All bound policies, categorized by industry type."
                            }
                            tooltipTitle={"Policies Bound"}
                            setMobileTooltipOpen={setMobileTooltipOpen}
                            setMobileTooltipTitle={setMobileTooltipTitle}
                            setMobileTooltipBody={setMobileTooltipBody}
                          />
                        </span>
                      </Flex>
                      <Flex
                        justifyContent="start"
                        alignItems="baseline"
                        className="space-x-2"
                      >
                        {loading ? (
                          <div className="mt-4 animate-pulse flex space-x-4">
                            <div className="rounded-md bg-gray-300 h-4 w-16"></div>
                          </div>
                        ) : (
                          <Metric>
                            {policiesByIndustryBarData.reduce(
                              (acc, curr) => acc + curr.value,
                              0
                            )}
                          </Metric>
                        )}
                        <Text>
                          {policiesByIndustryBarData.reduce(
                            (acc, curr) => acc + curr.value,
                            0
                          ) > 1
                            ? "Policies"
                            : "Policy"}{" "}
                          among {Object.keys(policiesByIndustryBarData).length}{" "}
                          industry type
                          {Object.keys(policiesByIndustryBarData).length > 1
                            ? "s"
                            : ""}
                        </Text>
                      </Flex>
                      <Flex className="mt-6">
                        <Text>Policy Type</Text>
                        <Text className="text-right">#</Text>
                      </Flex>
                      {loading ? (
                        <div className="mt-4 animate-pulse">
                          <div className="rounded-md bg-gray-300 h-8 w-full"></div>
                          <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                          <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                          <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                          <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        </div>
                      ) : (
                        <BarList
                          data={policiesByIndustryBarData}
                          valueFormatter={dataFormatter}
                          className="mt-2 scrollbar-none max-h-72 overflow-y-scroll"
                        />
                      )}
                    </Card>
                  )}

                  {/* {data.map((item) => (
                    <Card key={item.category} className="ring-0">
                      <Title>{item.category}</Title>
                      <Flex
                        justifyContent="start"
                        alignItems="baseline"
                        className="space-x-2"
                      >
                        <Metric>{item.stat}</Metric>
                        <Text>Total views</Text>
                      </Flex>
                      <Flex className="mt-6">
                        <Text>Pages</Text>
                        <Text className="text-right">Views</Text>
                      </Flex>
                      <BarList
                        data={item.data}
                        valueFormatter={dataFormatter}
                        className="mt-2"
                      />
                    </Card>
                  ))} */}
                </Grid>
                <Chart
                  title="Partner Revenue Share"
                  subtitle={`Total earned: ${numeral(
                    totalCommissionEarned
                  ).format("$0,0.00")}`}
                  data={chartData}
                  categories={["Partner Revenue Share"]}
                  index="date"
                  colors={["indigo"]}
                />
                {/* <div className="mt-8">
                  <h2 className="text-lg leading-6 font-medium text-alt">
                    Quick Links
                  </h2>
                  <ActionsList />
                </div>
                {policies && policies.length > 0 && (
                  <div className="mt-8">
                    <h2 className="text-lg leading-6 font-medium text-alt">
                      Recommended Policies
                    </h2>
                    <RecommendedPolicies policies={policies} />
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

const Selector = ({
  options,
  selected,
  handleChange,
}: {
  options: string[]
  selected: any
  handleChange: (selected: any) => void
}) => {
  return (
    <Select
      className={`block w-full sm:col-span-2 text-sm`}
      value={selected}
      onChange={handleChange}
      enableClear={false}
    >
      {options.map((option, index) => (
        <SelectItem key={index} value={option}>
          {option}
        </SelectItem>
      ))}
    </Select>
  )
}
