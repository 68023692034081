import { IApplicationSubmission, IPolicy } from "../../db/models"
import fetchPartnerDashboardService from "../../service/fetchPartnerDashboardService"

async function getApplicationSubmissionsWithAttribute(
  startDate: string,
  endDate: string,
  status: string
) {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      startDate,
      endDate,
      status,
    },
    "general/getApplicationSubmissionsWithAttribute"
  )
  return res.applicationSubmissions as IApplicationSubmission[]
}

/**
 * Fetches application submissions for a partner.
 *
 * @param {string} licenseId - The ID of the license.
 * @param {string} startDate - The start date for the submissions.
 * @param {string} endDate - The end date for the submissions.
 * @returns {Promise<ApplicationSubmission[]>} The response data.
 */
export async function getApplicationSubmissionsForPartner(
  licenseId: string,
  startDate: string,
  endDate: string
): Promise<Record<string, IApplicationSubmission[]>> {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      licenseId,
      startDate,
      endDate,
    },
    "general/getApplicationSubmissionsForPartner"
  )
  return res.applicationSubmissions as Record<string, IApplicationSubmission[]>
}

async function getBusinessesWithApplicationSubmissionsWithAttribute(
  licenseId: string,
  startDate: string,
  endDate: string,
  status: string
): Promise<number> {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      licenseId,
      startDate,
      endDate,
      status,
    },
    "general/getBusinessesWithApplicationSubmissionsWithAttribute"
  )
  return res.businessesWithApplicationSubmissions as number
}

async function getTotalPremiumForDates(
  startDate: string,
  endDate: string,
  licenseId: string
): Promise<number> {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      startDate,
      endDate,
      licenseId,
    },
    "general/getTotalPremiumForDates"
  )
  return res.totalPremium as number
}

async function getPoliciesForPartner(startDate: string, endDate: string) {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      startDate,
      endDate,
    },
    "general/getPoliciesForPartner"
  )
  return res as {
    policies: IPolicy[]
    endorsements: { [license: string]: number }
  }
}

const getTotalPartnerRevShare = async (
  licenseId: string,
  startDate: string,
  endDate: string
): Promise<{
  totalPartnerRevShare: number
  [date: string]: number
}> => {
  const res = await fetchPartnerDashboardService(
    "POST",
    {
      licenseId,
      startDate,
      endDate,
    },
    "general/getTotalPartnerRevShare"
  )
  return res.totalPartnerRevShare as {
    totalPartnerRevShare: number
    [date: string]: number
  }
}

export {
  getApplicationSubmissionsWithAttribute,
  getBusinessesWithApplicationSubmissionsWithAttribute,
  getTotalPremiumForDates,
  getPoliciesForPartner,
  getTotalPartnerRevShare,
}
